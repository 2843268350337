import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Wrapper from './wrapper';

const StyledFooter = styled.div`
  padding: 8px 0px;
  background-color: ${(props) => props.theme.blackPalette.original};
  color: ${(props) => props.theme.whitePalette.original};

  .company-name,
  .styled-links {
    margin: 8px 0px;
    font-weight: 600;
  }

  .styled-links {
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: none;
      margin: 0px 8px;
      color: inherit;
    }
  }

  @media only screen and (max-width: 767px) {
    .company-name {
      text-align: center;
    }
    .styled-links {
      justify-content: center;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <Wrapper>
      <Row type="flex" justify="space-between">
        <Col md={12} sm={24} xs={24} className="company-name">
          © GW Solutions, 2020. All rights reserved.
        </Col>
        <Col md={12} sm={24} xs={24} className="styled-links">
          <Link to="/#">Privacy Policy</Link>
          <Link to="/#">Terms and Conditions</Link>
        </Col>
      </Row>
    </Wrapper>
  </StyledFooter>
);

Footer.propTypes = {};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const Wrapper = ({ children, align, style }) => {
  return (
    <Row type="flex" justify="center" align={align} style={style}>
      <Col xxl={18} md={20} sm={23} xs={23}>
        {children}
      </Col>
    </Row>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  align: PropTypes.string,
};

Wrapper.defaultProps = {
  children: null,
  style: {},
  align: 'top',
};

export default Wrapper;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Button, Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Wrapper from './wrapper';
import LogoComponent from './logoComponent';

const links = [
  { label: 'WHO WE ARE', path: '#who-we-are' },
  { label: 'SERVICE BUNDLES', path: '#service-bundles' },
  { label: 'OUR PORTFOLIO', path: '#our-portfolio' },
  { label: 'WORK WITH US', path: '#work-with-us' },
];

const StyledHeader = styled.div`
  top: 0;
  z-index: 10;
  width: 100%;
  padding: 14px 0px;
  position: fixed;
  background-color: ${(props) => props.theme.blackPalette.original};
  color: ${(props) => props.theme.whitePalette.original};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px;
  margin-top: ${(props) => (props.navbarVisibility ? '0' : '-74px')};
  transition: 0.4s;

  .styled-logo {
    width: 72px;

    img {
      margin: 0;
    }
  }

  .link-wrapper-col {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: none;
      color: inherit;
      margin: 0px 16px;
      letter-spacing: 1px;
      font-family: Techna Sans Regular;
    }

    .ant-btn {
      color: inherit;

      .styled-hamburger {
        height: 30px;
      }
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background-color: ${(props) => props.theme.blackPalette.original};
    color: ${(props) => props.theme.whitePalette.original};

    .ant-drawer-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 18px 14px 16px;

      .ant-btn {
        width: 100%;
        color: inherit;
        text-align: right;

        .styled-close {
          height: 30px;
        }
      }

      .styled-logo {
        width: 72px;

        img {
          margin: 0;
        }
      }

      .links-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          text-decoration: none;
          color: ${(props) => props.theme.redPalette.light};
          margin: 16px 0px;

          h5 {
            margin: 0;
            letter-spacing: 1px;
          }
        }
      }

      p {
        margin-bottom: 12px;
        text-align: center;
      }
    }
  }
`;

const Header = () => {
  const [navbarVisibility, setNavbarVisibility] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop > 300) setNavbarVisibility(true);
      else setNavbarVisibility(false);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [navbarVisibility]);

  const drawerVisibilityHandler = () => {
    setDrawerVisibility(!drawerVisibility);
  };

  return (
    <StyledHeader navbarVisibility={navbarVisibility}>
      <Wrapper>
        <Row type="flex">
          <Col lg={4} sm={12} xs={12}>
            <div className="styled-logo">
              <AnchorLink href="#/">
                <LogoComponent />
              </AnchorLink>
            </div>
          </Col>
          <Col lg={20} sm={0} xs={0}>
            <div className="link-wrapper-col">
              {links.map((value) => (
                <AnchorLink offset="120" href={value.path} key={value.path}>
                  {value.label}
                </AnchorLink>
              ))}
            </div>
          </Col>
          <Col lg={0} sm={12} xs={12}>
            <div className="link-wrapper-col">
              <Button type="text" onClick={() => drawerVisibilityHandler()}>
                <FontAwesomeIcon icon="bars" className="styled-hamburger" />
              </Button>
            </div>
          </Col>
        </Row>
      </Wrapper>
      <StyledDrawer
        placement="right"
        closable={false}
        onClose={() => drawerVisibilityHandler()}
        visible={drawerVisibility}
        zIndex={11}
        width={320}
        getContainer="main"
      >
        <Button type="text" onClick={() => drawerVisibilityHandler()}>
          <FontAwesomeIcon icon="times" className="styled-close" />
        </Button>
        <div className="styled-logo">
          <AnchorLink href="#/">
            <LogoComponent />
          </AnchorLink>
        </div>
        <div className="links-wrapper">
          {links.map((value) => (
            <AnchorLink
              offset="120"
              href={value.path}
              key={value.path}
              onClick={() => drawerVisibilityHandler()}
            >
              <h5>{value.label}</h5>
            </AnchorLink>
          ))}
        </div>
        <div>
          <p>inquiry@gwsolutions.com</p>
          <p>(+63) 9165903894</p>
          <p>
            3/F Main GreatWork Ben- Lor IT Center, Diliman, Quezon City, Metro
            Manila
          </p>
        </div>
      </StyledDrawer>
    </StyledHeader>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;

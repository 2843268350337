/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import Header from './header';
import Footer from './footer';
import './layout.css';

library.add(fas, far);
config.autoAddCss = false;

const theme = {
  blackPalette: {
    light: '#818181',
    original: '#333333',
    dark: '#202020',
  },
  whitePalette: {
    light: '#fafafa',
    original: '#ffffff',
    dark: '#dadada',
  },
  redPalette: {
    light: '#FC7B6A',
    original: '#6B271E',
    dark: '#6B271E',
  },
};

const AppContainer = styled.div`
  color: ${`${theme.blackPalette.dark}E6`};

  * {
    font-family: 'Proxima Nova', 'Techna Sans Regular', 'Open Sans', san-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Techna Sans Regular', 'Proxima Nova', 'Open Sans', san-serif;
    margin-bottom: 14px;
    font-weight: 400;
  }

  p {
    margin-bottom: 14px;
    line-height: calc(1rem + 10px);
  }

  .has-error {
    > .ant-input {
      border: solid 1px #ff0000;
    }
  }

  .error-explain {
    font-size: 0.75rem;
    color: #ff0000;
    padding: 0px 8px;
    margin-top: 2px;
    margin-bottom: -10px;
  }

  .input-field-wrapper.has-error {
    margin-bottom: 10px;
  }
`;

const Layout = ({ children }) => (
  <AppContainer>
    <ThemeProvider theme={theme}>
      <Header />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </ThemeProvider>
  </AppContainer>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
